import {
  getKycDocs as getKycDocsService,
  verifyKycDocs as verifyKycDocsService,
} from "../../services/KycService";
import { KYC_ACTION, KYC_VERIFY_ACTION } from "./KycTypes";

export function getKycRequiredDocsAction(userId, config) {
  return (dispatch) => {
    getKycDocsService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          const mergedDocs = res.data.requiredDocs.map((doc) => {
            const uploadedDoc = res.data.uploadedDocs.find(
              (uploaded) => uploaded.type === doc.type
            );
            return {
              ...doc,
              uploaded: uploadedDoc ? true : false,
              file: uploadedDoc?.file || null,
            };
          });

          dispatch(getKycDocsConfirm({ list: mergedDocs, isLoading: false }));
        }
        if (typeof config.setIsLoading == "function")
          config.setIsLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        if (typeof config.setIsLoading == "function")
          config.setIsLoading(false);
      });
  };
}

export function verifyKycDocsAction(userId, config = {}) {
  return (dispatch) => {
    verifyKycDocsService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(verifyKycDocsConfirmAction(res.data.kycStatus));
          if (typeof config.showDialogOnSuccess == "function") {
            config.showDialogOnSuccess();
          }
        } else {
          if (typeof config.toast == "function") {
            config.toast("Exception!", res.data.message, "error");
          }
        }
        if (typeof config.setIsLoading == "function")
          config.setIsLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        if (typeof config.setIsLoading == "function")
          config.setIsLoading(false);
      });
  };
}

function verifyKycDocsConfirmAction(payload) {
  return {
    type: KYC_VERIFY_ACTION,
    payload,
  };
}

function getKycDocsConfirm(payload) {
  return {
    type: KYC_ACTION,
    payload,
  };
}
