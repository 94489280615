import api from "./AxiosInstance";
import { URL } from "./URL";
import axios from "axios";

export function getKycDocs(userId) {
  return api.get("kyc/docs/" + userId);
}

export function verifyKycDocs(userId) {
  return api.get("kyc/verify/" + userId);
}

export function uploadKycDoc(data, headers = {}) {
  // return api.post("kyc/upload", data);

  return axios({
    method: "POST",
    url: URL + "kyc/upload",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      ...headers,
    },
  });
}
