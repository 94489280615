import { KYC_ACTION } from "../actions/KycTypes";

const initialState = {
  list: [],
  isLoading: true,
};

export function KycReducer(state = initialState, action) {
  if (action.type === KYC_ACTION) {
    // console.log("action.payload", action.payload);
    return { ...state, ...action.payload };
  }

  return state;
}
