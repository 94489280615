import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { URL } from "./URL";

export function createWallet(data) {
  return axios({
    method: "POST",
    url: URL + "createWalletAccount",
    data,
  });
}

export function getCities() {
  return axios({
    method: "GET",
    url: URL + "cities",
  });
}

export function findWallet(address) {
  return axios({
    method: "GET",
    url: URL + "findWalletAccount/" + address,
  });
}

export function savePhone(userId, data) {
  return axios.post(URL + "savePhone/" + userId, data);
}

export function submitPassword(data) {
  return axios.post(URL + "submitPassword", data);
}

export function sendOtp(data) {
  return axios.post(URL + "sendOtp", data);
}

export function verifyOtp(userId, data) {
  return axios.post(URL + "verify/otp/" + userId, data);
}

export function matchEmail(email) {
  return axios({
    method: "GET",
    url: URL + "users/match/by/e/" + email,
  });
}

export function validatePhone(phone) {
  return axios({
    method: "GET",
    url: URL + "users/match/by/p/" + phone,
  });
}

export function updateProfile(userId, data, headers) {
  return axios({
    method: "PUT",
    url: URL + "users/update/" + userId,
    data,
    headers,
  });
}

export function getMapAPIKeyService() {
  return axios({
    method: "GET",
    url: URL + "mapKey",
  });
}

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(data) {
  return axios({
    method: "POST",
    url: URL + "login",
    data,
  });
}

export function register(data) {
  return axios({
    method: "POST",
    url: URL + "registration",
    data,
  });
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails.expiresIn * 1000
  );
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
