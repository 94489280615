import { isAuthenticated } from "./AuthSelectors";

export function getKycList(state) {
  if (isAuthenticated(state)) return state.kyc.list || [];
  else return [];
}

export function getKycLoadingState(state) {
  if (isAuthenticated(state)) return state.kyc.isLoading;
  else return false;
}
